var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ValidationObserver",
    { ref: "form", staticClass: "flex-1 pr-2 overflow-hidden" },
    [
      _c("input-validation", {
        ref: "validationProvider",
        staticClass: "flex-1",
        attrs: {
          label: _vm.label,
          rules: { required: { allowFalse: false } },
          vid: "validationProvider",
          name: "validationProvider",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _vm._v(" " + _vm._s(_vm.emitHasError(errors)) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hasAttached,
                      expression: "hasAttached",
                    },
                  ],
                  ref: "inputShadow",
                  attrs: { type: "hidden" },
                  domProps: { value: _vm.hasAttached },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.hasAttached = $event.target.value
                    },
                  },
                }),
                _c("Dropdown", {
                  class: { "p-invalid": errors[0] },
                  attrs: {
                    options: _vm.source.options,
                    "option-label": "optionLabel",
                    "option-value": "optionValue",
                    placeholder: _vm.placeholder,
                    "option-disabled": "optionDisabled",
                    filter: true,
                    "data-key": "optionValue",
                  },
                  on: { change: _vm.handleOptionEmpty },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (slotProps) {
                          return [
                            _c(
                              "div",
                              { staticClass: "dropdown-option-disabled" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(slotProps.option.optionLabel)),
                                ]),
                                slotProps.option.optionDisabled
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.translateAlreadyEnteredBy(
                                              slotProps.option.alreadyEnteredBy
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.source.selected,
                    callback: function ($$v) {
                      _vm.$set(_vm.source, "selected", $$v)
                    },
                    expression: "source.selected",
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }