
import mixins from 'vue-typed-mixins';
import ProfileManagementFormMixin from '@/resources/profile-management/components/forms/ProfileManagementFormMixin';

export default mixins(ProfileManagementFormMixin).extend({
  name: 'HierarchyBusinessLevelDropdown',

  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasAttached: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasErrors: false,
    };
  },

  methods: {
    translateAlreadyEnteredBy(alreadyEnteredBy: 'companyGroup' | 'company'): string {
      const name = this.businessHierarchyTranslate(alreadyEnteredBy);

      return `${this.$t('profileManagement.alreadyEnteredBy') as string} ${name}`;
    },

    async handleOptionEmpty() {
      const { validationProvider, inputShadow, form } = this.$refs as any;

      await validationProvider.validate(inputShadow);
      if (!this.hasAttached) {
        form.setErrors({
          validationProvider: [this.$t('profileManagement.form.dropdownLinkValidation.msnAddValueSelected') as string],
        });
      }
    },

    emitHasError(errors: string[]) {
      const validationHasErrors = errors.length > 0;
      if (this.hasErrors !== validationHasErrors) {
        this.hasErrors = validationHasErrors;
        this.$emit('hasErrors', this.hasErrors);
      }
    },
  },
});
